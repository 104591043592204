import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

const Terms = ({ location }) => (
  <Layout location={location} >
    <SEO title="Terms of Use" />
    <div class="row">		
      <div class="col-md-12">
        <h1>Terms of Use</h1>
        <p>M.L Wray Automotive website is owned by M.L Wray Automotive and operated by DSS Digital. By accessing, browsing or using this website, you agree to the terms, conditions and disclaimers herein as amended from time to time.</p>
        <h3>Trademarks, Copyrights and Restrictions</h3>
        <p>All material on this site, including, but not limited to images, logos and illustrations, is protected by copyrights, trademarks, and other intellectual property rights which are owned and controlled by M.L Wray Automotive and its related companies. The content of www.mlwray.com.au is provided for your personal, non-commercial use. Such material may not be copied, republished, reproduced, uploaded, modified, transmitted, posted, or distributed in any way without the consent of M.L Wray Automotive</p>
        <p>The use of this website and these conditions is in accordance with the laws of Australia. Any legal action arising out of its use shall be brought and enforced under Australian laws. By using this site, you agree to submit to the jurisdiction of the courts of Australia and any legal action pursued by you shall be within the exclusive jurisdiction of the courts of Australia</p>
        <h3>Links to other sites</h3>
        <p>M.L Wray Automotive is not responsible for the privacy practices or the content of other sites that are linked to www.mlwray.com.au</p>
      </div>
    </div>
  </Layout>
)

export default Terms;